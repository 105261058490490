.usermgmtpage .filters-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.usermgmtpage .filters-container > div {
    margin-right: 10px !important;
}

.usermgmtpage .titlebox {
    display: flex;
    justify-content: space-between;
}
.usermgmtpage .buttons-filter, .usermgmtpage .searchbox {
    margin-right: 20px;
}
.usermgmtpage .top-section {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    justify-content: space-between;
}
.usermgmtpage .MuiFormControl-marginDense {
    margin-bottom: unset !important;
}
.usermgmtpage .searchbox {
    margin-bottom: unset !important;
}
.usermgmtpage .buttons-filter > button{
    margin-right: 10px !important;
}
.usermgmtpage .user-export-icon{
    color: var(--hti-magenta);
    cursor: pointer;
}

button.adduserbtn {
    margin-left: 20px;
}