
.classification {
    min-height: 10vh;
    
}
.desc-section {
  
    text-align: initial;
}

.each-section {
    width : 16.5em;
    /* height: 13.7em; */
    height: auto;
    overflow: auto;
    margin : 10px 10px 10px 20px;
    text-align: left;
    cursor: pointer;
    border: 1px solid #dddddd;
    box-sizing: border-box;
}
.each-section-new-card {
    width : 16.5em;
    margin : 10px 10px 10px 20px;
    text-align: left;
    cursor: pointer;
    border: 1px solid #dddddd;
    box-sizing: border-box;
}
.each-section-category {
    width : 35.2em;
    height: 7em;
    margin : 10px 10px 10px 20px;
    text-align: left;
    cursor: pointer;
    border: 1px solid #dddddd;
    box-sizing: border-box;
}
.each-category {
    margin-right: 2.5em;
}
.each-section-category:hover {
    box-shadow: 10px;
    box-sizing: border-box;
    border: 1px solid var(--hti-purple-70);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  
}
.each-section:hover {
    box-shadow: 10px;
    box-sizing: border-box;
    border: 1px solid var(--hti-purple-70);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  
  
}

.each-section:hover .icon-review{
  visibility: visible;
}
.each-section-category:hover .icon-review{
    visibility: visible;
  }
.card-flag {
    visibility: visible;
    width : 6%;
    position : relative;
    z-index: 1;
}
.card-flag-hide {
    display : none;
}
.cat-section-view{
    text-align: left;
}
.cat-section-view:hover .icon-review{
    visibility: visible;

}
.each-box-view {
   /* display: flex;
    justify-content: space-between;*/
    margin : 0px 10px 10px 10px;
}
.icon-review {
   visibility: hidden;
   
}
.add-button-review {
    text-align: center !important;
    color : var(--hti-magenta) !important;
    margin:10px;
}
.conf-label {
    font-size: 0.8em;
    line-height: 1em;
    text-align: right;
    margin: 0px;
    margin-top : 5px;
    color: #000000;
   
}
.conf-value {
    margin-left: 10px;
    color : var(--hti-magenta);
    font-weight: 400;
}
.add-section {
    width : 9em;
    height : 8em;
    margin : 10px 25px 10px 25px;
    border:  1px solid #dddddd;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
}
.add-section:hover{
    box-shadow: 10px;
    box-sizing: border-box;
    border: 1px solid var(--hti-purple-70);
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(113, 24, 101, 0.1);

box-sizing: border-box
}
.add-section-disable {
    opacity: 0.5;
    cursor: auto;
    width : 9em;
    height : 8em;
    margin : 10px 25px 10px 25px;
    border:  1px solid #dddddd;
    box-sizing: border-box;
    text-align: center;

}
.icon-review-style {
    color : var(--hti-magenta);
    margin : 5px;
    cursor: pointer;
    opacity: 0.8;

}
.icon-review-style:hover {
    opacity: initial;
}
.icon-disable {
    color : lightgray;
    cursor : auto;
    margin : 5px;
}
.left-fields {
    margin: 0px 20px 10px;
    word-break: break-word;
}
.hide-section {
    visibility: hidden;
}
.class-category {
    margin-left: 13em;
}
.card-title {
    font-size: 1.25em;
    color : var(--hti-title);
    margin : 0px;
}
.reset-icon {
    margin-left : 13em;
    color : var(--hti-magenta);
}
.tri-flag {
    width: 0;
    height: 0;
    border-top: 13px solid var(--hti-magenta);
    border-right: 13px solid transparent;
    position: absolute;
}
.save-button {
    visibility: visible;
}
.save-button-hide {
    visibility: hidden;
}

.icon-text-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-error-text {
    margin: 0px 0px 5px 0px;
    color : red;
}
.block-pm {
    height: 32px;
    background: var(--hti-magenta);
    border-radius: 4px;
    margin-right: 20px;
    border: none;
    cursor: not-allowed;
}
.flag-notes-section {
   
    text-align: left;
    margin: 0px 20px 5px 20px;
    width: 95%;
    display: flex;
    align-items: baseline;
}
.notes-section {
    word-break: break-all;
    max-width: 45%;
    margin-top: 0.8em;
    margin-left: 0.8em;

}
.notes-label{
    /* font-weight: 300; */
    color : var(--hti-magenta);
}
.button-section {
    text-align: right;
    margin: 10px;
}
.scrollable-cards{
    /*height: 65vh;
    overflow: scroll;*/
}
.category-display{
    display: flex;
    margin: 0 20px;
}

.doc-desc-section{
    margin: 0.8em 20px;
    max-width: 95%;
    word-break: break-all;
    text-align: left;
}
.warning_msg {
    background: #fff4e5;
    color: #663c00;
    padding: 0.6em 0 0.6em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}
.msg-content{
    width: 100%;
    flex-wrap: wrap;
}

.warning_msg div {
    display: flex;
    align-items: center;
}

.warning_msg div svg {
    font-size: 1.3rem;
    color: #ff9800;
}

.warning_msg svg {
    font-size: 1.2rem;
    padding-right: 0.5rem;
    cursor: pointer;
}

.showLink:hover {
    text-decoration: underline;
    cursor: pointer;
}

.icon-remove-style {
    visibility: hidden;
}