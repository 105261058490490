.intelligence-metrics {
     min-height: 20vh;
}
.doc-status {
     /* background-image: linear-gradient( #e5e5e5 ,white) !important;*/
    min-height: 80vh;
     background: linear-gradient( rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0) 100%);

  }
  .flow-chart-part1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 }
 
 .flow-chart-part1 p {
    margin: 0px 15px;
 }
 .flow-chart-part2, .flow-chart-part3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
 }
 
 .flow-chart-part2 p, .flow-chart-part3 p {
    margin: 2px 0px;
 }
 
 .flex-justify-unique {
    margin-left: -2.5em !important;
    margin-bottom: 3rem !important;
    margin-top: 1rem !important;
 }
 
 .bottom-status-background-circle {
    background: #dfdfdf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .bottom-status-background-circle.right-circle {
    height: 52px;
    width: 52px;
    border-bottom-left-radius: 0px;
    transform: rotate(45deg);
 }
 .bottom-status-background-circle.right-circle > button {
    transform: rotate(-45deg);
 }

 .bottom-status-background-circle.left-circle {
    height: 45px;
    width: 45px;
    border-bottom-right-radius: 0px;
    transform: rotate(-45deg);
    margin-top: 2px;
 }
 .bottom-status-background-circle.left-circle > button {
    transform: rotate(45deg);
 }
 .bottom-status button, .flow-chart-part1 button {
    width: 35px;
    height: 35px;
 }

 .bottom-status.left-circle > .number-style{
    margin-top: 7px;
 }

 .background-circle-connector {
    height: 10px;
    width: 50%;
    background: #dfdfdf;
    position: relative;
    top: 42px;
 }
 
 .bottom-status {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
 }
.vertical-line {
    width: 0px;
    min-height: 3vh;
    margin-top: 0.8em;
    border: 1px solid #736e6e;
    background-color: #736e6e;
  }
.left-line {
    position: absolute;
    width: 92px;
    height: 92px;
    left: 71px;
    top: 616px;
    border: 1px solid #000000;
}

.right-line {
    position: absolute;
    width: 92px;
    height: 92px;
    left: 277px;
    top: 616px;
    border: 1px solid #000000;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
/*
.diagonals {
    display: flex;
    justify-content: center;
    margin-top: -13%;
}

.diag-left {
    position: absolute;
    width: 0px;
    min-height: 18vh;
    border: 1px solid #736e6e;
    background-color: #736e6e;
    transform: rotate(52deg);
  }
  .diag-right {
    position: absolute;
    width: 0px;
    min-height: 18vh;
    border: 1px solid #736e6e;
    background-color: #736e6e;
    transform: rotate(-52deg);
  }
  .diag-left-boundary{
      height: 13vh;
      width : 12.6vh;
  }*/
  .metrics-align {
      padding : 1em;
  }
  .icon-margin {
   margin : 0em 0.8em 0em 0.8em;
  }
  .flex-justify-chart-icon {
    display: flex;
    justify-content: space-between;
    margin : -15px 12% 0px 14%;
}
.logs-tablet {
    display: none;
}
.hide-status{
    visibility: hidden;
}
.show-status{
    visibility: visible;
}
.approved-section:hover .status-detail{
    visibility: visible;
}
.status-detail {
    visibility: hidden;
    margin-top: 15px;
    z-index: 1;
    position: absolute;
    height : 5vh;
    width: 6vw;
    margin-left : -1%;
}
.status-split {
   
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: pointer;
}
.custom-vertical {
    border : 1px solid #a8a8a8;
    height: 100%;
}
.number-style {
    font-weight: 300;
    font-size: 1.2em;
    margin: 0px;
    text-align: center;
    color: #000000;
  }
.mini-number-style {
    font-weight: 300;
    margin: 0px;
    text-align: center;
    color: #000000;
}
.xs-number-style {
    font-weight: 300;
    margin: 0px;
    text-align: center;
    color: #000000;
    font-size: 0.8em;
}
.mini-text-status {
    font-size: 0.8em;
    line-height: 1em;
    text-align: center;
    margin: 0px;
    color: #000000;
  }
.mini-text-status:hover {
    text-decoration: underline;
}
.flex-chart-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.custom-divider-chart{
    border: 1px solid #4f4f4f40;
    margin: 0.5em;
}
.doc-status-chart {
    display: flex;
    margin: 1em 0.8em 1em 0.8em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .doc-numbers {

    display: flex;
    /*margin : 0.8em 0em 0.8em 0.8em;*/
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 75px;
    margin : 0.8em;
  }
  .chart-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .curve-divider{
    width: 18vw;
    border-top: 1px solid #4f4f4f40;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;
  
    }
    .chart-bottom-status{
     display: flex;
    justify-content: space-between; 
    width: 75%;
    margin-top: 12px;
    }
    .chart-bottom-status .bottom-status{
        width: 7vw;
        align-items: center;
    }
    .status-icon-action {
        color : white;
    }
    .status-icon-action :hover{
        opacity: 0.5;
    }
  /*=======================Laptop large==================*/
  @media only screen and (min-width: 1500px){
     
      .metrics-align {
          padding : 1em 1em 2em 1em;
      }
      .chart-top {
          margin-top : 3em;
      }
      .icon-margin{
        margin : 0em 1em 0em 0.8em;
      }
      .logs-tablet {
          display: none;
      }
      .custom-divider-chart {
          margin : 1.3em
      }
      .number-style{
          font-size: 1.5em;
      }
      .vertical-line{
          min-height: 3.5vh;
          margin-top: 1.8em;
      }
     
  }
/*=====================Tablet====================*/
@media only screen and (max-width: 900px){
    .diag-left-boundary{
        width : 10.6vh;
    }
    .diag-left {
        min-height: 13vh;
    }
    .diag-right {
        min-height: 13vh;
    }
    .flex-justify-chart-icon {
        margin : -14% 0% 0px 0%;
        justify-content: space-around;
    }
    .intelligence-metrics {
        min-height: 15vh;
    }
    .doc-status {
        min-height: 50vh;
    }
    .logs-tablet {
        display: grid;
        background-color: white;
    }
    .flow-chart-part1 {
        min-height: 25vh !important;
    }
}
/*============================extra large screen===========*/
@media only screen and (min-width: 1900px){
     
    .metrics-align {
        padding : 2em 1em 2em 1em;
    }
}