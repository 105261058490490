.rp-avg-page-row {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 10px;
}
.rp-avg-page-row-count {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-around;
    width : 42%
}
.rp-title {
    font-size: 1.2em;
    line-height: 23px;
    text-align: left;
    color: var(--hti-title);
    margin: 0px;
    padding: 15px;
}
.rp-avg-data {
    width : 50%;
    text-align: center;
}

.rp-paper {
    display: flex;
    justify-content: center;
}
@media screen {
    .rp-paper-print-cat {
        display: none;
    } 
    .rp-paper-print-loc {
        display: none;
    }       
    .fake-pg-brk{
        display: none;
    }
}

@media print {
    .rp-print-right-text {
        width : 2cm;
        display : flex;
        justify-content: inherit;
    }
    .rp-paper{
        display: none;
    }
    .rp-paper-print-cat {
        margin-top: 0.5cm !important; 
        padding: 0.5cm !important;
    }
    .rp-paper-print-loc {
        margin-top: 0.5cm !important; 
        padding: 0.5cm !important;
    }
    .rp-print-text {
        margin : 0.05cm;
        text-align: left;
    }
   
}

