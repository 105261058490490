
#doc-loc-box {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
}
.loc-name {
   /* width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;*/
    text-align: left;
    margin : 0px;
}