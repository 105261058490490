.title-box-flex {
    height: 43px;
    width : 100%;
     background: rgba(113, 24, 101, 0.1);
     display: flex;
     justify-content: space-between;
     align-items: center;
}



  .edit-text-style {
    color : var(--hti-title);
    margin-right:5px;
  }
  .edit-action {
    display: flex;
    align-items: center;
    cursor: pointer;
    height : 30px;
    border-right: 1px solid rgba(170, 21, 114, 0.3);;
    padding: 0px 3px 0px 3px;
    

  }
  .edit-action:hover{
    background-color: rgb(226, 221, 221, 0.5);
  
  }
  
 
  .key-value {
      display: flex;
      align-items: center;
  }
  .heading {
    text-align: justify;
    text-align-last: right;
    width : 65%;
    font-weight: 400;
    margin-right: -15px;
  }
  .values {
      text-align: justify;
      width : 50%;
      font-weight: 300;
  }
  .right-plane-width-new {
    position: fixed;
    z-index: 500;
    /*overflow-y: scroll;*/
    height: 100%;
    width : 25%;
    right : 0;
    box-sizing: border-box !important;
    transition: transform 0.3s ease-out !important;
    transform : translateX(0) !important;
    
    box-shadow: 0 0 15px #9c9c9c !important;
  }

  .img-div{
   
    margin : 30px auto auto;
    width : 20vw;
    overflow: auto;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    height : 50vh

    
  
  }
  .img-div-pdf{
    margin : 30px auto auto;
    width : 20vw;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    height : 50vh
  }
  
  #docImage {
    cursor: pointer;
  }