.flex-margin-logs {
    display: flex;
    justify-content: space-between;
    margin : 10px;
   
}

  .flex-style-logs {
    display: flex;
    
}
.logs-view{
  max-height: 100vh;
  overflow-y: scroll;
}
.log-bg:first-child{
  background-color: #ececec;
}
