.rp-avg-page-row {
    display: flex;
    align-items: center;
    flex-flow: unset !important;
    justify-content: space-between;
    margin: 10px;
}
.location-table .rp-avg-page-row-count {
    display: flex;
    /* align-items: center;
    flex-flow: wrap; */
    /* justify-content: space-around; */
    width : 50px;
    padding: 2px 10px;
}
.location-table .rp-left-text {
    width : 240px;
    display: flex;
}
.location-table .search-input-field {
    width: 240px;
}
.location-table .table-body {
    width: 100%;
    overflow-y: auto;
}
.location-table .rp-title {
    font-size: 1.2em;
    line-height: 23px;
    text-align: left;
    color: var(--hti-title);
    margin: 0px;
    padding: 5px 15px;
}
.location-table .paper-title {
    display: flex;
    align-items: center;
    height: auto;
    padding: 5px 0px;
}
.rp-avg-data {
    text-align: center;
}

.rp-paper {
    display: flex;
    justify-content: center;
}

@media screen {
    .rp-paper-print-cat {
        display: none;
    } 
    .rp-paper-print-loc {
        display: none;
    }       
    .fake-pg-brk{
        display: none;
    }
}

@media print {
    .rp-print-right-text {
        width : 2cm;
        display : flex;
        justify-content: inherit;
    }
    .rp-paper{
        display: none;
    }
   .rp-paper-print-cat {
       margin-top: -2.5cm;
       margin-bottom: 1.5cm;
     }
    .rp-print-text {
        margin : 0.05cm;
        text-align: left;
    }
   /* .fake-pg-brk {
        break-before: always !important;
    }*/
   
}

