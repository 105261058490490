.each-header-data {
   /* width : 22%;*/
    text-align: left;
    margin-right : 1em;   
}

.parent-flex {
    display: flex;
    justify-content: space-between;
}
.grey-txt{
    margin: 0px;
    font-size : 0.75em;
    color: #a2a0a0;
    text-align: left;
    display: flex;
    align-items: center;
}

.block-stat {
    display: flex;
    align-items: center;
    cursor: not-allowed !important;
}
.nav-icon {
    display: flex;
    color : var(--hti-magenta);
    padding : 3px 0px;
    border-radius: 3px;
  
}

.nav-icon:hover{
        background-color: var(--hti-hover);
}
.toggle-yes {
    height: 1.25em;
    width: 3.2em;
    background-color: var(--hti-magenta);
    border: 0.063em solid var(--hti-magenta);
    border-radius: 3.2em;
    
}
.toggle-no {
    height: 1.25em;
    width: 3.2em;
    border: 0.063em solid var(--hti-magenta);
    border-radius: 3.2em;
    
}

.point-cursor {
    cursor: pointer;
}
.knob-yes {
    width: 0.87em;
    height: 0.87em;
    border-radius: 0.87em;
    background-color:white;
    border: 0.063em solid white;
    /*margin-top: 0.1em;*/
  
   
}
.text-yes {
    color: white;
    font-size: 0.9em;
    margin-left: 3px;
}
.text-no {
    color : var(--hti-magenta);
    font-size: 0.9em;
    margin-right: 2px;
}
.knob-no {
    width: 0.87em;
    height: 0.87em;
    border-radius: 0.87em;
    border: 0.063em solid var(--hti-magenta);
    /*margin-top: 0.1em;*/
   
   
}
.knob-action {
    display: flex;
    align-items: center;
    justify-content: space-around;   
    /*-webkit-transition : all 5s ease-in-out;
    transition: all 5s ease-in-out;*/
    
}
.text-color {
    color : var(--hti-magenta);
    font-weight: 400;
    margin-right: 7px;
}
.all-flags {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}
.header-section .header-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.header-section .header-info .left-section {
    display: flex;
    /*justify-content: space-between;*/
    margin-right: 5px;
}

.filter-menu .MuiMenuItem-root{
    padding : 20px !important;
  }
.filter-menu {
    width : 60% !important;
 
}
.ftr-label {
    font-weight: 500;
}
.ftr-each {
    margin: 5px 10px 5px 10px;
}
.mr-flex-btwn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
}
.nav-buttons {
    margin-right: 1em;
}
.label-font-flex {
    font-weight : 300;
    margin : 0px;
}
.value-font-flex{
    margin : 0px 0px 0px 7px;
}
.display-section {
    margin: 10px 20px 5px 20px;
}
.failure-msg {
    color: #EB5757;
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}
.doc-info-msg {
    color: var(--hti-magenta);
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}
/*===============large screen=========================
@media only screen and (min-width: 1700px){
    .header-section .header-info .left-section {
        width : 60%;
       
    }
}*/
