.highlight-ocr {
    position: absolute;
    /* width:75px;
      height:75px;
      top: 109px;
      left:75px;*/
    visibility: visible;
   /* border: 3px solid yellow;*/
    background-color: yellow;
    mix-blend-mode: multiply;
    /*border-radius: 2px 2px 2px 0;
    transform : rotate(-45deg);*/
    opacity: 0.5;
  }

.canvas-class {
  background-image: url('../../utils/images/pdf_jpg.jpeg');
}