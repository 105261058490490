.react-draggable {
    position: fixed;
    z-index: 1001;
    box-shadow:  0px 0px 4px 1px rgba(0,0,0,0.14), 0px 3px 5px 2px rgba(0,0,0,0.12) !important;
    border-radius: 5px;
    overflow: auto;
    background-color: #fff;
    max-height: 90%;
    max-width: 80%;
}

/* .react-draggable::-webkit-scrollbar { 
    display: none;
} */

.draggable-header-content {
    background: linear-gradient(96.29deg, var(--hti-title) -25.11%, var(--hti-pink) 98.84%);
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    height: 35px;
}

.draggable-header {
    cursor: move;
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 1;
}

.draggable-header-icons{
    cursor: pointer;
    align-items: center;
    display: flex;
}

.draggable-header-icons > svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.draggable-header-icons > svg:last-child {
    width: 25px;
    height: 25px;
}

.patient-create-title {
    font-size: 1.3em;
    font-weight: 500;
}

.draggable-body {
    padding: 15px;
    background-color: #fff;
    overflow: auto;
}