.fetch-id-popup {
    width : 500px;
    align-items: center;
}
.id-fetch-button {
    color : var(--hti-magenta);
    cursor: pointer;
    display: flex;
}
.id-fetch-button:hover{
    text-decoration: underline;
}

.fetch-popup-header{
    display: flex;
    justify-content: space-between;
    background-color: var(--hti-magenta);
}
.fetch-button-section{
    display: flex;
    text-align: left;
    margin: 5px 20px 5px 20px;
    flex-flow: wrap;
}
.ehr-accordion {
    width: 100%;
    margin-right: 0.5em;

}
.ehr-accordion-header {
    color : var(--hti-magenta);
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.ehr-screen-display{
    max-height: 12em;
    overflow: scroll;
    pointer-events: visible !important;
    cursor: auto !important;
}
.ehr-screen-display th{
    padding: 0.2em 2em 0.5em 0;
    text-align: left;
    font-weight: 600;
}
.ehr-screen-display td{
    padding: 0 2em 0.5em 0;
    text-align: left;
}
.ehr-success {
    color: #27AE60;
    display: flex;
    align-items: center;
}
.ehr-success > svg, .ehr-failure > svg {
    margin-right: 5px;
}

.ehr-success > td, .ehr-failure > td {
    padding: unset;
}

.ehr-failure {
    color: #EB5757;
    display: flex;
    align-items: center;
}
