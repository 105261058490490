.chart-style {
    font-size: 10px !important;
}
.apexcharts-text tspan{
    font-size : 0.75em !important;
    margin-right : 20px;
}
.apexcharts-legend-text {
    font-size: 0.75em !important;
}
.apexcharts-legend {
    position:  relative !important;
    justify-content: flex-end !important;
}