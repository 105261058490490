.sa-lock-screen {
    text-align: left;
    margin: 30px;
}
.sa-menu-list {
    display: grid;
}
.sa-each-menu {
    cursor: pointer;
    margin-bottom: 10px !important;
    font-size: 1.2em;
    text-decoration: none !important;
    color : black !important;
    width: fit-content;
}
.sa-each-menu:hover{
    text-decoration: underline !important;
    color : var(--hti-magenta) !important;
  
}

.sa-cmnt-dialog {
    margin: 20px;
}
.sa-buttons {
    color : var(--hti-magenta) !important;
}
.sa-bg-highlight {
    background-color: #c1e1eb;
}
.mui-buttons-custom {
    color : var(--hti-magenta) !important;
    border : 1px solid var(--hti-magenta) !important;
}
.mui-buttons-custom-disabled{
    color : var(--hti-magenta) !important;
    border : 1px solid var(--hti-magenta) !important;
    opacity : 0.5 !important;
}
.mui-buttons-custom-filled {
    background-color: #b3b3b3 !important;
   
}