.patient-creation-wrapper {
    display: flex;
    padding: 0px 20px;
}

.patient-creation-wrapper > div {
    flex: 1;
}

.patient-creation-right-portion {
    border-left: 1px solid var(--hti-magenta) !important;
    padding-left: 20px;
}

.practitioner-details-form {
    margin-top: 25px;
}

.patient-details-form .form-fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

/* .patient-details-form .form-field-item {
    flex: 1;
} */

.form-fields {
    display: flex;
    flex-flow: wrap;
}

.form-sub-title {
    display: flex;
    margin: 10px 0px;
    font-weight: 500;
    color: var(--hti-magenta);
}

.patient-information-title {
    display: flex;
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 1.3em;
    font-weight: 400;
    /* color: var(--hti-magenta); */
}

.form-field-item {
    margin: 10px 20px 20px 0px!important;
    /* width: fit-content !important; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.form-input-select.Mui-focused {
    background-color: unset !important;
}

.form-input-select > div {
    display: flex;
}

.patient-creation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
}

.patient-creation-buttons .pt-btn {
    width: fit-content;
    background: var(--hti-magenta);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    border: none;
    padding: 10px 30px;
    color: #fff;
}
.patient-creation-buttons .btn-submit {
    background: var(--hti-magenta);
}
.patient-creation-buttons .btn-submit:hover {
    background: var(--hti-gradient);
}
.patient-creation-buttons .pt-btn.btn-cancel {
    background: #fff;
    border: 1px solid var(--hti-magenta);
    color: var(--hti-magenta);
}
.patient-creation-buttons .pt-btn.btn-cancel:hover {
    background-color: #F1E7EF;
}

span.required-icon {
    color: red;
    font-size: 1.1rem;
    font-weight: 500;
}

.form-field-label {
    font-weight: 300;
}
.form-field-item div.MuiInputBase-formControl {
    margin-top: 5px !important;
    font-weight: 400 !important;
}

.form-field-item button.MuiButtonBase-root {
    padding: unset !important;
    padding-top: 2px !important;
}

.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    display: block;
    padding-left: 10px;
}

.loading-block-area-common {
    opacity: 1;
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.form-field-item-groups {
    width: 100%;
    display: flex;
    flex-flow: wrap;
}


